<template>
    <!-- 경영공시 START -->
	<div class="content_container management_notice_list">
		<div class="content">
			<div class="banner">
				<h1>경영 공시</h1>
			</div>
			
            <ListBoard whichBoard="manageDisclosure" />
		</div>
	</div>
    <!-- 경영공시 END -->
</template>

<script>
import ListBoard from '@/components/ListBoard'

export default {
    name : "MagageDisclosure",
	components : {
		ListBoard,
	},
}
</script>

